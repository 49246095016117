export const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
};

//Split the address in many fields
export const formatAddressDetail = (place) => {
    const streetNumberMatch = place.streetNumber
        ? place.streetNumber.toString().match(/\d+/g)
        : null;
    const streetNumber = streetNumberMatch ? streetNumberMatch[0] : '';
    const streetSuffixMatch = place.streetNumber
        ? place.streetNumber.toString().match(/[a-zA-Z]+/g)
        : null;
    const streetSuffix = streetSuffixMatch
        ? streetSuffixMatch[0]
        : '';

    const streetName = place.streetName
        ? place.streetName
        : '';
    const postcode = place.postcode ? place.postcode : ''
    const cityName = place.city ? place.city:''

    return {
        fullAddress: place.address,
        streetNumber,
        streetSuffix,
        streetName,
        postcode,
        cityName
    };
};
