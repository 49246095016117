import axios from 'axios';

axios.defaults.baseURL = process.env.API_BASE_URL || 'http://localhost:3069';
axios.defaults.headers.common = {
    Accept: 'application/json, application/xml, text/play, text/html, *.*',
    'Content-Type': 'application/json',
};

export const setApiToken = (token) => {
    axios.defaults.headers['X-Api-Key'] = token;
};

export default axios;
