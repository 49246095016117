import React from 'react';
import ProgressBar from './ProgressBar/ProgressBar';
import Address from './Address/Address';
import Specs from './Specs/Specs';
import Map from './Map/Map';
import Contact from './Contact/Contact';
import Valuation from './Valuation/Valuation';
import ValuationSvg from './Valuation/ValuationSvg';
import { v4 as uuidv4 } from 'uuid';

import { setApiToken } from '../lib/request';
import {
    getWidgetColor,
    getCityTxCount,
    getTeasing,
    generateValuation,
    setWidgetUrl,
    incrementVisitorCount,
} from '../services/widgetApi';
import { titleCase, formatAddressDetail } from '../lib/utils';

const DEFAULT_COLOR = '#696CFF';
export default class Widget extends React.Component {
    constructor() {
        super();
        this.state = {
            currentStep: 1, // Step in the widget funnel
            place: {}, // Place returned by the geocoding
            specs: null, // Specs of the property
            bottomText: null, // Text to display on the bottom of the map
            contactInfo: null, // Contact information
            valuation: null, // Valuation of the property
            color: DEFAULT_COLOR, // Widget theme color
        };
    }

    componentDidMount() {
        setApiToken(imdtToken);

        // increment visitor counter if new visitor
        this.incrementVisitorCount();

        // Set Color
        this.setColor();

        // Set Widget URL
        this.setUrl();
    }

    componentDidUpdate(prevProps) {
        // Specific behaviour if we load the widget into a react app and want to set the color through props
        const { widgetColor } = this.props;
        if (widgetColor && widgetColor !== prevProps.widgetColor)
            this.setColor(widgetColor);
    }

    incrementVisitorCount = async () => {
        try {
            const uuid = localStorage.getItem('imdt-uuid');
            const host = window.location.host

            //We count visitors on other websites than Immo Data (the widget is displayed on immo data dashboard)
            if (!uuid && host !== "immo-data.fr") {
                const newUuid = uuidv4();
                localStorage.setItem('imdt-uuid', newUuid);
                await incrementVisitorCount();
            }
        } catch (err) {
            // Do nothing
        }
    };

    setUrl = async () => {
        try {
            const body = {
                url:
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    window.location.pathname,
            };
            await setWidgetUrl(body);
        } catch (err) {
            // Do nothing
        }
    };

    setColor = async (initialColor) => {
        const root = document.documentElement;
        try {
            // Get the color of the widget
            const color = initialColor || (await getWidgetColor());
            root.style.setProperty(
                '--imdt-widget-primary-color',
                color ? color : DEFAULT_COLOR
            );
            root.style.setProperty(
                '--imdt-widget-primary-color-lighter',
                (color ? color : DEFAULT_COLOR) + '55'
            );
            root.style.setProperty(
                '--imdt-widget-primary-color-lightest',
                (color ? color : DEFAULT_COLOR) + '22'
            );
            if (color) this.setState({ color });
        } catch (err) {
            root.style.setProperty(
                '--imdt-widget-primary-color',
                DEFAULT_COLOR
            );
            root.style.setProperty(
                '--imdt-widget-primary-color-lighter',
                DEFAULT_COLOR + '55'
            );
            root.style.setProperty(
                '--imdt-widget-primary-color-lightest',
                DEFAULT_COLOR + '22'
            );
        }
    };

    // ------------------- ADDRESS  -------------------

    //Callback to set the address and switch to the next step
    setAddress = (addressDetails) => {
        //Get some stats to display to the user
        if (addressDetails) {
            this.getCityTransactionsCount(addressDetails);
        } else {
            this.setState({
                place: addressDetails,
                currentStep: 2,
            });
        }
    };

    //Get the number of transactions in the city to display it at the bottom of the map.
    getCityTransactionsCount = async (addressDetails) => {
        try {
            const { center, postcode, city } = addressDetails
            const longitude =  center && center.length > 1 ? center[0] : null;
            const latitude = center && center.length > 1 ? center[1] : null
            const txCount = postcode && longitude && latitude
                ? await getCityTxCount(longitude, latitude, postcode)
                : 0;
            const bottomText = `<p><span class="imdt-highlight">${txCount
                .toString()
                .replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ' '
                )}</span> ventes identifiées récemment sur ${
                city
            } (${postcode})</p>`;
            this.setState({
                bottomText: txCount && txCount > 0 ? bottomText : '',
                place: addressDetails,
                currentStep: 2,
            });
        } catch (err) {}
    };

    // ------------------- SPECS  -------------------

    //Callback to set the property specs and switch to the next step
    setSpecs = (specs) => {
        const { place } = this.state;
        if (specs && place && place.center) {
            this.getValuationTeasingInfo(place.center, specs);
        } else {
            this.setState({ specs, currentStep: 3 });
        }
    };

    //Get some stats to display to the user at the bottom of the map
    getValuationTeasingInfo = async (center, specs) => {
        try {
            const teasingData = await getTeasing({
                surface: specs.surface,
                longitude: center[0],
                latitude: center[1],
                nbRooms: specs.nbRooms,
                propertyType: specs.propertyType,
            });
            let bottomText = '';
            if (
                teasingData &&
                teasingData.similarTransactionsCount &&
                teasingData.similarTransactionsCount > 0
            ) {
                bottomText +=
                    '<p><span class="imdt-highlight">' +
                    teasingData.similarTransactionsCount +
                    '</span> ventes comparables identifiées';

                bottomText += '</p>';
            }
            if (teasingData && teasingData.walkScore !== null) {
                bottomText +=
                    '<p>Analyse des services de proximité - Score Piéton de <span class="imdt-highlight">' +
                    teasingData.walkScore +
                    '/100</span></p>';
            }
            this.setState({ bottomText, specs, currentStep: 3 });
        } catch (err) {}
    };

    // ------------------- CONTACT  -------------------

    //Callback to set the contact info and display the final screen with the valuation
    setContactInfo = (contactInfo) => {
        const { place, specs } = this.state;
        if (contactInfo && place && place.center && specs) {
            this.getValuation(place, specs, contactInfo);
        } else {
            this.setState({ contactInfo, currentStep: 4 });
        }
    };

    //Get the property valuation
    getValuation = async (place, specs, contactInfo) => {
        const addressDetail = formatAddressDetail(place);
        const body = {
            fullAddress: addressDetail.fullAddress,
            streetNumber: addressDetail.streetNumber,
            streetSuffix: addressDetail.streetSuffix,
            streetName: addressDetail.streetName,
            postcode:addressDetail.postcode,
            cityName:addressDetail.cityName,
            surface: specs.surface,
            longitude: place.center[0],
            latitude: place.center[1],
            surfaceLand: specs.surfaceLand ? specs.surfaceLand : 0,
            nbRooms: specs.nbRooms,
            propertyType: specs.propertyType,
            firstName: contactInfo.firstName,
            lastName: contactInfo.lastName,
            tel: contactInfo.tel,
            email: contactInfo.email,

        };
        try {
            const valuation = await generateValuation(body);
            this.setState({ valuation, contactInfo, currentStep: 4 });
        } catch (err) {}
    };

    render() {
        const { currentStep, place, bottomText, valuation, color } = this.state;
        return (
            <div id="reset-style">
                <div id="imdt-container">
                    <div className={'imdt imdt-widget'}>
                        <ProgressBar currentStep={currentStep} color={color} />
                        {currentStep === 1 && (
                            <Address setAddress={this.setAddress} />
                        )}
                        {currentStep > 1 && (
                            <div className="imdt-wrapper">
                                <div className="imdt-left-panel-wrapper">
                                    {currentStep === 2 && (
                                        <Specs setSpecs={this.setSpecs} />
                                    )}
                                    {currentStep === 3 && (
                                        <Contact
                                            setContactInfo={this.setContactInfo}
                                        />
                                    )}
                                    {currentStep === 4 && valuation && (
                                        <Valuation valuation={valuation} />
                                    )}
                                </div>

                                <div className="imdt-right-panel-wrapper">
                                    {place &&
                                        place.center &&
                                        (currentStep === 2 ||
                                            currentStep === 3) && (
                                            <Map
                                                center={place.center}
                                                addressDetails={place}
                                                bottomText={bottomText}
                                            />
                                        )}
                                    {currentStep === 4 && valuation && (
                                        <div className="imdt-valuation-report">
                                            <ValuationSvg color={color} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
