import request from '../lib/request';

export const getWidgetColor = () =>
    request.get(`api/widget/color`).then(({ data }) => data);

export const setWidgetUrl = (body) =>
    request.post(`api/widget/url`, body).then(({ data }) => data);

export const incrementVisitorCount = () =>
    request.put(`api/widget/visitor-count`).then(({ data }) => data);

export const getCityTxCount = (longitude, latitude, postcode) =>
    request
        .get(`api/widget/city-tx-count?longitude=${longitude}&latitude=${latitude}&postcode=${postcode}`)
        .then(({ data }) => data);

export const getTeasing = (params) =>
    request
        .get(
            `api/widget/teasing?surface=${params.surface}&longitude=${params.longitude}&latitude=${params.latitude}&nbRooms=${params.nbRooms}&propertyType=${params.propertyType}`
        )
        .then(({ data }) => data);

export const generateValuation = (body) =>
    request.post(`api/widget/valuation`, body).then(({ data }) => data);

export const verifyEmail = (email) =>
    request
        .get(`api/widget/verify-email?email=${email}`)
        .then(({ data }) => data);
