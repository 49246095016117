import React from 'react';
export default class ValuationSvg extends React.Component {
    render() {
        const { color } = this.props;
        return (
            <svg
                id="e6a4d201-d3b7-4c8a-84c5-8bf2997c6bc3"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                width="932"
                height="567.58611"
                viewBox="0 0 932 567.58611"
            >
                <path
                    id="a31475c4-f0df-4934-8dee-db246d5650fa"
                    data-name="Path 438"
                    d="M173.377,684.43344a24.21459,24.21459,0,0,0,23.38269-4.11877c8.18977-6.87441,10.758-18.196,12.8467-28.68191l6.17973-31.01657-12.9377,8.90837c-9.30465,6.40641-18.81826,13.01866-25.26011,22.29785s-9.25223,21.94707-4.07792,31.988"
                    transform="translate(-134 -166.20694)"
                    fill="#e6e6e6"
                />
                <path
                    id="bc80039d-7499-47e3-8dd4-b917794dfbc0"
                    data-name="Path 439"
                    d="M175.37466,724.144c-1.62839-11.86368-3.30382-23.88078-2.15884-35.87167,1.01467-10.64932,4.26373-21.04881,10.87831-29.57938a49.2061,49.2061,0,0,1,12.62466-11.44039c1.26215-.79647,2.42409,1.20354,1.16733,1.997a46.77949,46.77949,0,0,0-18.50446,22.32562c-4.02857,10.24607-4.67545,21.41582-3.98154,32.3003.41944,6.58218,1.31074,13.1212,2.20588,19.65251a1.19817,1.19817,0,0,1-.808,1.42251,1.16348,1.16348,0,0,1-1.42253-.808Z"
                    transform="translate(-134 -166.20694)"
                    fill="#f2f2f2"
                />
                <path
                    id="a24c42db-7472-45f4-900c-466dbf3f7d22"
                    data-name="Path 442"
                    d="M187.09569,705.10755a17.82511,17.82511,0,0,0,15.53141,8.01862c7.8644-.37318,14.41806-5.85973,20.31713-11.07027l17.452-15.4088-11.54987-.55281c-8.30619-.39784-16.82672-.771-24.73813,1.79339s-15.20758,8.72638-16.654,16.9154"
                    transform="translate(-134 -166.20694)"
                    fill="#e6e6e6"
                />
                <path
                    id="a7d2fb0b-6149-49f2-88d6-cc03571334a8"
                    data-name="Path 443"
                    d="M170.77168,730.96369c7.83972-13.87142,16.93234-29.28794,33.1808-34.21552a37.02609,37.02609,0,0,1,13.95545-1.441c1.48189.128,1.11179,2.41174-.367,2.28454a34.39833,34.39833,0,0,0-22.27164,5.89215c-6.27994,4.27454-11.16975,10.21756-15.30781,16.51907-2.53511,3.86051-4.80576,7.88445-7.07642,11.903C172.1595,733.19039,170.03767,732.26285,170.77168,730.96369Z"
                    transform="translate(-134 -166.20694)"
                    fill="#f2f2f2"
                />
                <rect
                    id="b85ae1dc-b170-4f54-a6de-4a72451950d7"
                    data-name="Rectangle 62"
                    x="175.25235"
                    y="17.77538"
                    width="648.36576"
                    height="307.6608"
                    fill="#e6e6e6"
                />
                <rect
                    id="f015ec3e-20cc-40e8-8473-d753a9bdb685"
                    data-name="Rectangle 75"
                    x="193.79258"
                    y="44.15783"
                    width="611.2862"
                    height="256.69103"
                    fill="#fff"
                />
                <rect
                    id="ed585d6d-4c60-4aae-9dcd-e3012a759289"
                    data-name="Rectangle 80"
                    x="174.97588"
                    width="648.36576"
                    height="27.5445"
                    fill={color}
                />
                <circle
                    id="b43fe0fb-7393-4bbe-8dcd-48ed75608891"
                    data-name="Ellipse 90"
                    cx="195.44487"
                    cy="12.74061"
                    r="5.10527"
                    fill="#fff"
                />
                <circle
                    id="ada3e127-c3a1-446f-8730-bf32ed0f9b11"
                    data-name="Ellipse 91"
                    cx="214.8228"
                    cy="12.74061"
                    r="5.10527"
                    fill="#fff"
                />
                <circle
                    id="e3927273-efa9-4ef7-88ca-a022a07a6d22"
                    data-name="Ellipse 92"
                    cx="234.20163"
                    cy="12.74061"
                    r="5.10527"
                    fill="#fff"
                />
                <path
                    d="M472.16718,406.99667H781.11811V354.04354a41.92864,41.92864,0,0,0-12.86035-29.86572l-.084-.082c-5.51806-5.53662-23.95922-19.58838-43.03955-18.397-10.0791.62744-18.70825,5.48486-25.64721,14.4375-21.59912,27.87744-49.97168,18.46728-64.96192,10.37695-13.21069-7.13135-26.70215-10.47851-40.10229-9.9458-18.76709.73389-45.18189,7.64746-64.44605,36.41748-7.35766,10.97949-23.35888,25.8833-57.80957,34.64209Z"
                    transform="translate(-134 -166.20694)"
                    fill="#e4e4e4"
                />
                <path
                    d="M798.59839,408.22816H472.76177a1.01977,1.01977,0,0,1-1.01972-1.01972V251.03494a1.01972,1.01972,0,0,1,2.03944,0V406.18872h324.8169a1.01972,1.01972,0,0,1,0,2.03944Z"
                    transform="translate(-134 -166.20694)"
                    fill="#3f3d56"
                />
                <rect
                    x="398.7414"
                    y="181.96453"
                    width="2"
                    height="58.62158"
                    fill="#3f3d56"
                />
                <rect
                    x="458.61811"
                    y="154.58611"
                    width="2"
                    height="86"
                    fill="#3f3d56"
                />
                <rect
                    x="518.61811"
                    y="172.58611"
                    width="2"
                    height="68"
                    fill="#3f3d56"
                />
                <rect
                    x="578.61811"
                    y="139.58611"
                    width="2"
                    height="101"
                    fill="#3f3d56"
                />
                <rect
                    x="638.61811"
                    y="162.58611"
                    width="2"
                    height="78"
                    fill="#3f3d56"
                />
                <circle
                    cx="459.74144"
                    cy="155.96462"
                    r="6.11832"
                    fill={color}
                />
                <circle
                    cx="579.74144"
                    cy="139.96462"
                    r="6.11832"
                    fill={color}
                />
                <circle
                    cx="519.74144"
                    cy="172.96462"
                    r="6.11832"
                    fill={color}
                />
                <circle
                    cx="399.74144"
                    cy="181.96462"
                    r="6.11832"
                    fill={color}
                />
                <circle
                    cx="639.74144"
                    cy="161.96462"
                    r="6.11832"
                    fill={color}
                />
                <polygon
                    points="136.913 555.469 149.173 555.469 155.005 508.181 136.911 508.182 136.913 555.469"
                    fill="#ffb6b6"
                />
                <path
                    d="M267.78543,717.6737l24.144-.001h.001A15.38731,15.38731,0,0,1,307.31693,733.059v.5l-39.53076.00147Z"
                    transform="translate(-134 -166.20694)"
                    fill="#2f2e41"
                />
                <polygon
                    points="194.913 555.469 207.173 555.469 213.005 508.181 194.911 508.182 194.913 555.469"
                    fill="#ffb6b6"
                />
                <path
                    d="M325.78543,717.6737l24.144-.001h.001A15.38731,15.38731,0,0,1,365.31693,733.059v.5l-39.53076.00147Z"
                    transform="translate(-134 -166.20694)"
                    fill="#2f2e41"
                />
                <path
                    d="M351.79286,505.49135a10.7427,10.7427,0,0,0-2.51239-16.27991l-19.95137-95.789-21.73592,8.56372,26.4463,91.46467a10.80091,10.80091,0,0,0,17.75338,12.04055Z"
                    transform="translate(-134 -166.20694)"
                    fill="#ffb6b6"
                />
                <path
                    d="M257.76441,487.045l-9.03648,9.17282s-9.68839,35.50336,8.85089,79.28929l12.4,126.94994,22.31,1.67994,6.66005-147.69558,30.72994,49.0256L324.31125,703.612l24.66119-.56693,11.14567-108.752L329.67881,480.8088Z"
                    transform="translate(-134 -166.20694)"
                    fill="#2f2e41"
                />
                <path
                    d="M289.36079,499.40243A345.94729,345.94729,0,0,1,248.6623,496.714l-.54346-.07226,13.46656-59.18555-4.637-76.15088,43.7876-1.5a16.08951,16.08951,0,0,1,16.50513,13.76367l8.96191,60.75538,8.29,53.90234-.11108.16211C328.42451,497.12411,308.50605,499.40243,289.36079,499.40243Z"
                    transform="translate(-134 -166.20694)"
                    fill={color}
                />
                <path
                    d="M296.752,372.40022l8.89685-11.15322s15.59694-10.666,20.53312,12.19,14.10739,59.96025,14.10739,59.96025l-30.498,2.46974Z"
                    transform="translate(-134 -166.20694)"
                    fill={color}
                />
                <path
                    d="M212.44336,506.49135a10.7427,10.7427,0,0,1,2.51239-16.27991l19.95137-95.789,21.73592,8.56372-26.4463,91.46467a10.80091,10.80091,0,0,1-17.75338,12.04055Z"
                    transform="translate(-134 -166.20694)"
                    fill="#ffb6b6"
                />
                <path
                    d="M267.48425,373.40022,258.5874,362.247s-15.59694-10.666-20.53312,12.19-14.10739,59.96025-14.10739,59.96025l30.498,2.46974Z"
                    transform="translate(-134 -166.20694)"
                    fill={color}
                />
                <path
                    d="M304.9783,348.66834h-58a4.50508,4.50508,0,0,1-4.5-4.5v-25a33.5,33.5,0,1,1,67,0v25A4.50508,4.50508,0,0,1,304.9783,348.66834Z"
                    transform="translate(-134 -166.20694)"
                    fill="#2f2e41"
                />
                <circle
                    cx="148.38189"
                    cy="156.39447"
                    r="24.56103"
                    fill="#ffb6b6"
                />
                <path
                    d="M317.40359,321.66834H281.938l-.36377-5.0918-1.81836,5.0918h-5.46094l-.7207-10.0918-3.604,10.0918H259.40359v-.5a26.52977,26.52977,0,0,1,26.49976-26.5h5.00024a26.53,26.53,0,0,1,26.5,26.5Z"
                    transform="translate(-134 -166.20694)"
                    fill="#2f2e41"
                />
                <path
                    d="M281.64776,353.33509a4.5963,4.5963,0,0,1-.79639-.07031l-25.96948-4.582V305.76283h28.5874l-.70776.82519c-9.84717,11.48438-2.42847,30.10645,2.87012,40.18457a4.43343,4.43343,0,0,1-.3523,4.707A4.48192,4.48192,0,0,1,281.64776,353.33509Z"
                    transform="translate(-134 -166.20694)"
                    fill="#2f2e41"
                />
                <polygon
                    points="864.323 555.469 852.064 555.469 846.231 508.181 864.326 508.182 864.323 555.469"
                    fill="#9e616a"
                />
                <path
                    d="M1001.45005,733.56042l-39.53076-.00147v-.5a15.38731,15.38731,0,0,1,15.38648-15.38623h.001l24.144.001Z"
                    transform="translate(-134 -166.20694)"
                    fill="#2f2e41"
                />
                <polygon
                    points="820.323 555.469 808.064 555.469 802.231 508.181 820.326 508.182 820.323 555.469"
                    fill="#9e616a"
                />
                <path
                    d="M957.45005,733.56042l-39.53076-.00147v-.5a15.38731,15.38731,0,0,1,15.38648-15.38623h.001l24.144.001Z"
                    transform="translate(-134 -166.20694)"
                    fill="#2f2e41"
                />
                <path
                    d="M811.5379,390.32037a11.39632,11.39632,0,0,0,17.434,1.19462l24.25852,9.4731,9.35227-13.33777-34.44561-12.8967a11.45809,11.45809,0,0,0-16.5992,15.56675Z"
                    transform="translate(-134 -166.20694)"
                    fill="#9e616a"
                />
                <polygon
                    points="820.169 304.925 788.229 315.344 798.899 541.54 823.549 542.15 836.164 382.633 839.029 541.52 869.508 543.2 880.744 304.925 820.169 304.925"
                    fill="#2f2e41"
                />
                <path
                    d="M932.61811,337.79306l81.97476-17.81106,9.11584,70.585-7.5906,33.72606,3.04043,51.254s-64.81863,32.26134-96.92953,6.00367c0,0,25.809-67.78972,10.84908-99.52369Z"
                    transform="translate(-134 -166.20694)"
                    fill="#3f3d56"
                />
                <path
                    d="M946.815,347.777l-14.19685-9.98394s-.83858-1.16142-12.66929,12.66929-35.83071,35.83071-35.83071,35.83071l-40-15-7.2874,26.92494,46.48819,21.664,65.816-43.95511Z"
                    transform="translate(-134 -166.20694)"
                    fill="#3f3d56"
                />
                <path
                    d="M1019.17338,502.0057a11.39628,11.39628,0,0,0,4.42162-16.90625l13.82655-22.069-11.362-11.67333-19.088,31.44a11.45808,11.45808,0,0,0,12.20183,19.20864Z"
                    transform="translate(-134 -166.20694)"
                    fill="#9e616a"
                />
                <path
                    d="M1001.80709,333.887l12.78578-13.905s14.24626-4.96114,25.88575,20.675,24.63949,77.6361,24.63949,77.6361l-30.39487,58.54724-23.60513-10.54724,23.60513-48.41339L1009.74409,382.027Z"
                    transform="translate(-134 -166.20694)"
                    fill="#3f3d56"
                />
                <circle
                    cx="967.36312"
                    cy="290.84412"
                    r="26.60917"
                    transform="translate(-154.96036 333.4395) rotate(-28.66316)"
                    fill="#9e616a"
                />
                <path
                    d="M992.38944,269.57145l1.29-2.59637-6.49095-3.225s-7.16055-11.651-20.11784-8.3768-18.787,5.23371-18.787,5.23371l-6.47453,3.25776,3.24953,3.23319-5.82954,1.95959,3.89455,1.935-4.53141,2.60456,2.43935,13.35188s4.0516-10.12689,11.84068-6.25692,22.0365-2.00053,22.0365-2.00053l12.37786,23.95508s2.55356-8.39745,7.1064-6.1583C994.39314,296.48832,1006.02446,277.96461,992.38944,269.57145Z"
                    transform="translate(-134 -166.20694)"
                    fill="#2f2e41"
                />
                <path
                    d="M434,733.79306H135a1,1,0,0,1,0-2H434a1,1,0,0,1,0,2Z"
                    transform="translate(-134 -166.20694)"
                    fill="#cacaca"
                />
                <path
                    d="M1065,733.79306H836a1,1,0,0,1,0-2h229a1,1,0,0,1,0,2Z"
                    transform="translate(-134 -166.20694)"
                    fill="#cacaca"
                />
            </svg>
        );
    }
}
