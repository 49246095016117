import React from 'react';
import Geocoder from './Geocoder';
import './Search.scss';

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
        };
    }

    onInputChange = (val) => {
        this.setState({ address: val });
    };

    processContext = (context) => {
        return context
            ? context.reduce((acc, el) => {
                  if (el.id && el.id.startsWith('postcode')) {
                      return {
                          ...acc,
                          postcode: el.text,
                      };
                  }
                  if (el.id && el.id.startsWith('place')) {
                      return {
                          ...acc,
                          city: el.text,
                      };
                  }
                  if (el.id && el.id.startsWith('region')) {
                      return {
                          ...acc,
                          region: el.text,
                      };
                  }
                  if (el.id && el.id.startsWith('country')) {
                      return {
                          ...acc,
                          country: el.text,
                      };
                  }
                  return acc;
              }, {})
            : null;
    };

    onSelect = (value) => {
        //If it is not an exact address, we have a bbox.
        //If there is a bbox specified, center and zoom are not used by Mapbox
        const bbox = value.bbox
            ? [
                  [value.bbox[0], value.bbox[1]],
                  [value.bbox[2], value.bbox[3]],
              ]
            : null;
        const center = value.center;
        const zoom = [18];
        const streetNumber =
            value.place_type &&
            value.place_type[0] === 'address' &&
            value.address
                ? value.address
                : '';
        const streetName =
            value.place_type && value.place_type[0] === 'address' && value.text
                ? value.text
                : '';
        const address = value.place_name;

        const context = this.processContext(value.context);

        this.setState({ address: value.place_name }, () =>
            this.props.setAddress({
                center: center,
                zoom: zoom,
                bbox: bbox,
                streetNumber: streetNumber,
                streetName: streetName,
                address: address,
                ...context,
            })
        );
    };

    render() {
        return (
            <div>
                <Geocoder
                    onSelect={this.onSelect}
                    focusOnMount={false}
                    inputClass="imdt-input imdt-input-address"
                    inputPlaceholder={this.props.inputPlaceholder}
                    defaultInputValue={this.state.address}
                    resultsClass="imdt-suggestions"
                    resultClass="imdt-address-suggestion"
                    onInputChange={this.onInputChange}
                    country="fr,mq,gp,gf,re,yt"
                />
            </div>
        );
    }
}

export default Search;
