import React from 'react';

import './Specs.scss';

export default class Specs extends React.Component {
    constructor() {
        super();
        this.state = {
            nbRooms: null,
            propertyType: null,
            surface: null,
            surfaceLand: null,
            surfaceErrorMsg: null,
            surfaceLandErroMsg: null,
        };
    }

    toggleNbRooms = (nbRooms) => {
        this.setState({ nbRooms });
    };
    togglePropertyType = (propertyType) => {
        this.setState({ propertyType });
    };
    setSurface = (event) => {
        const surface = event.target.value;
        if (isNaN(!parseFloat(surface))) {
            this.setState({ surfaceErrorMsg: 'Doit être un nombre' });
        } else if (parseFloat(surface) < 0) {
            this.setState({
                surfaceErrorMsg: 'Doit être un nombre supérieur à 0',
            });
        } else if (parseFloat(surface) > 1000000000) {
            this.setState({
                surfaceErrorMsg: 'Doit être un nombre inférieur à 1000000000',
            });
        } else {
            this.setState({ surface, surfaceErrorMsg: null });
        }
    };
    setSurfaceLand = (event) => {
        const surfaceLand = event.target.value;
        if (surfaceLand && isNaN(parseFloat(surfaceLand))) {
            this.setState({ surfaceLandErroMsg: 'Doit être un nombre' });
        } else if (parseFloat(surfaceLand) < 0) {
            this.setState({
                surfaceLandErroMsg: 'Doit être un nombre supérieur à 0',
            });
        } else if (parseFloat(surfaceLand) > 1000000000) {
            this.setState({
                surfaceLandErroMsg:
                    'Doit être un nombre inférieur à 1000000000',
            });
        } else {
            this.setState({
                surfaceLand,
                surfaceLandErroMsg: null,
            });
        }
    };

    isFormValid = (
        nbRooms,
        propertyType,
        surface,
        surfaceErrorMsg,
        surfaceLandErroMsg
    ) => {
        return (
            nbRooms &&
            propertyType &&
            surface &&
            !surfaceErrorMsg &&
            !surfaceLandErroMsg
        );
    };
    submitForm = () => {
        const { propertyType, nbRooms, surface, surfaceLand } = this.state;
        this.props.setSpecs({
            propertyType,
            nbRooms,
            surface,
            surfaceLand,
        });
    };

    render() {
        const {
            nbRooms,
            propertyType,
            surface,
            surfaceErrorMsg,
            surfaceLandErroMsg,
        } = this.state;
        const isFormValid = this.isFormValid(
            nbRooms,
            propertyType,
            surface,
            surfaceErrorMsg,
            surfaceLandErroMsg
        );
        return (
            <div className="imdt-specs-form-wrapper">
                <p className="imdt-form-label-first">Type</p>
                <div className="imdt-flex-form-container">
                    <div
                        className={
                            'imdt-type-item' +
                            (propertyType === 'Maison' ? ' imdt-is-active' : '')
                        }
                        onClick={() => this.togglePropertyType('Maison')}
                    >
                        Maison
                    </div>
                    <div
                        className={
                            'imdt-type-item' +
                            (propertyType === 'Appartement'
                                ? ' imdt-is-active'
                                : '')
                        }
                        onClick={() => this.togglePropertyType('Appartement')}
                    >
                        Appartement
                    </div>
                </div>
                <p className="imdt-form-label">Nombre de pièces</p>
                <div className="imdt-flex-form-container">
                    <div
                        className={
                            'imdt-type-item' +
                            (nbRooms === 1 ? ' imdt-is-active' : '')
                        }
                        onClick={() => this.toggleNbRooms(1)}
                    >
                        1
                    </div>
                    <div
                        className={
                            'imdt-type-item' +
                            (nbRooms === 2 ? ' imdt-is-active' : '')
                        }
                        onClick={() => this.toggleNbRooms(2)}
                    >
                        2
                    </div>
                    <div
                        className={
                            'imdt-type-item' +
                            (nbRooms === 3 ? ' imdt-is-active' : '')
                        }
                        onClick={() => this.toggleNbRooms(3)}
                    >
                        3
                    </div>
                    <div
                        className={
                            'imdt-type-item' +
                            (nbRooms === 4 ? ' imdt-is-active' : '')
                        }
                        onClick={() => this.toggleNbRooms(4)}
                    >
                        4
                    </div>
                    <div
                        className={
                            'imdt-type-item' +
                            (nbRooms === 5 ? ' imdt-is-active' : '')
                        }
                        onClick={() => this.toggleNbRooms(5)}
                    >
                        5+
                    </div>
                </div>
                <div className="imdt-wrapper-row">
                    <div className="imdt-wrapper-column">
                        <p className="imdt-form-label">Surface en m²</p>
                        <input
                            type="number"
                            className={
                                'imdt-input imdt-input-surface ' +
                                (surfaceErrorMsg ? 'imdt-input-error' : '')
                            }
                            min="0"
                            max="1000000000"
                            onChange={this.setSurface}
                        />

                        <p className="imdt-error-message">{surfaceErrorMsg}</p>
                    </div>
                    <div className="imdt-wrapper-column">
                        <p className="imdt-form-label">Surface terrain en m²</p>
                        <input
                            type="number"
                            className={
                                'imdt-input imdt-input-surface-land ' +
                                (surfaceLandErroMsg ? 'imdt-input-error' : '')
                            }
                            onChange={this.setSurfaceLand}
                            min="0"
                            max="1000000000"
                        />
                        <p className="imdt-error-message">
                            {surfaceLandErroMsg}
                        </p>
                    </div>
                </div>

                <p className="imdt-specs-button-wrapper">
                    <button
                        className="imdt-button"
                        disabled={!isFormValid}
                        onClick={() => this.submitForm()}
                    >
                        Suivant
                    </button>
                </p>
            </div>
        );
    }
}
