import React from 'react';
import ReactDOM from 'react-dom';
import Widget from './Widget/Widget';
import './styles/style.scss';

const supportedAPI = ['init']; // enlist all methods supported by API

/**
    The main entry of the application
    */
function app(window) {
    // all methods that were called till now and stored in queue
    // needs to be called now
    let globalObject = window[window['imdt-widget']];
    if (!globalObject) {
        globalObject = {};
        (globalObject.q = []).push(['init']);
    }
    let queue = globalObject.q;
    if (queue) {
        for (var i = 0; i < queue.length; i++) {
            apiHandler(queue[i][0], queue[i][1]);
        }
    }

    // override temporary (until the app loaded) handler
    // for widget's API calls
    globalObject = apiHandler;
}

/**
    Method that handles all API calls
    */
function apiHandler(api, params) {
    if (!api) throw Error('API method required');
    api = api.toLowerCase();

    if (supportedAPI.indexOf(api) === -1)
        throw Error(`Method ${api} is not supported`);

    if (api === 'init') {
        ReactDOM.render(
            <div>
                <Widget />
            </div>,
            document.getElementById('imdt-widget')
        );
    }
}

app(window);

export default app;
