import React from 'react';
import './Map.scss';

export default class Map extends React.Component {
    createMarkup() {
        return { __html: this.props.bottomText };
    }

    formatAddress = (streetNumber, streetName) => {
        const sNumber = streetNumber ? streetNumber + ' ' : '';
        const sName = streetName ? streetName : '';
        return sNumber + sName;
    };

    render() {
        const { center, addressDetails, bottomText } = this.props;
        return (
            <div>
                <div className="imdt-map-wrapper">
                    <img
                        id="map-image"
                        src={`${process.env.API_BASE_URL}/mapbox/styles/v1/damt/cjvs88d4b042v1cnuylcxfopy/static/${center[0]},${center[1]},15/300x300@2x`}
                        width="510"
                        height="100%"
                    />
                    <div className="imdt-map-marker"></div>
                    {addressDetails &&
                        addressDetails.address &&
                        addressDetails.postcode &&
                        addressDetails.city && (
                            <div className="imdt-map-address">
                                <p>
                                    {this.formatAddress(
                                        addressDetails.streetNumber,
                                        addressDetails.streetName
                                    )}
                                </p>
                                <p>
                                    {addressDetails.postcode}{' '}
                                    {addressDetails.city}
                                </p>
                            </div>
                        )}
                    {bottomText && (
                        <div
                            className="imdt-map-bottom-text"
                            dangerouslySetInnerHTML={this.createMarkup()}
                        ></div>
                    )}
                </div>
            </div>
        );
    }
}

Map.defaultProps = {
    center: [-0.586083, 44.856413],
    addressDetails: {
        address: '81 Rue De Macau',
        city: 'Bordeaux',
        postcode: '33000',
    },
    bottomText: `<p><strong>892</strong> ventes comparables identifiées</p>
    <p>Analyse du quartier <strong>SAINTE-AVOYE 2</strong> réalisée</p>
    <p>Analyse des services de proximité... Score piéton de <strong>97/100</strong></p>`,
};
