import React from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import fr from 'react-phone-number-input/locale/fr.json';
import 'react-phone-number-input/style.css';
import { verifyEmail } from '../../services/widgetApi';
import './Contact.scss';

export default class Contact extends React.Component {
    constructor() {
        super();
        this.state = {
            firstName: null,
            lastName: null,
            email: null,
            tel: null,
            telCountry: null,
            optin: false,
            firstNameErrorMsg: null,
            lastNameErrorMsg: null,
            emailErrorMsg: null,
            telErrorMsg: null,
        };
    }

    setFirstName = (event) => {
        const firstName = event.target.value;
        const format = /[!@#$%^&*(),.?":{}|<>/]/;
        if (firstName && firstName.length > 50) {
            this.setState({
                firstNameErrorMsg: 'Doit être inferieur à 50 caractères',
            });
        } else if (firstName && format.test(firstName)) {
            this.setState({
                firstNameErrorMsg:
                    'Ne doit pas comporter de caractères spéciaux.',
            });
        } else {
            this.setState({
                firstName,
                firstNameErrorMsg: null,
            });
        }
    };

    setLastName = (event) => {
        const lastName = event.target.value;
        const format = /[!@#$%^&*(),.?":{}|<>/]/;
        if (lastName && lastName.length > 50) {
            this.setState({
                lastNameErrorMsg: 'Doit être inferieur à 50 caractères',
            });
        } else if (lastName && format.test(lastName)) {
            this.setState({
                lastNameErrorMsg:
                    'Ne doit pas comporter de caractères spéciaux.',
            });
        } else {
            this.setState({
                lastName,
                lastNameErrorMsg: null,
            });
        }
    };

    setEmail = (event) => {
        const email = event.target.value;
        this.setState({
            email,
            emailErrorMsg: null,
        });
    };

    setTel = (value) => {
        this.setState({
            tel: value,
            telErrorMsg: null,
        });
    };

    setOptin = (event) => {
        if (document.getElementById('imdt-optin').checked) {
            this.setState({ optin: true });
        } else {
            this.setState({ optin: false });
        }
    };

    isFormCompleted = () => {
        const {
            firstName,
            lastName,
            email,
            tel,
            optin,
            firstNameErrorMsg,
            lastNameErrorMsg,
            telErrorMsg,
            emailErrorMsg,
        } = this.state;
        return (
            firstName &&
            lastName &&
            email &&
            tel &&
            optin &&
            !firstNameErrorMsg &&
            !lastNameErrorMsg &&
            !telErrorMsg &&
            !emailErrorMsg
        );
    };

    submitForm = async () => {
        const { firstName, lastName, email, tel } = this.state;

        const isMobileNumberValid =
            tel && isPossiblePhoneNumber(tel);
        if (!isMobileNumberValid) {
            this.setState({
                telErrorMsg: 'N° Téléphone invalide',
            });
        }

        // CHECK EMAIL FORMAT
        const emailRegex = new RegExp(
            '^[a-z0-9]+([_|.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|.|-]{1}[a-z0-9]+)*[.]{1}[a-z]{2,6}$',
            'i'
        );
        const isEmailInvalid = email && !emailRegex.test(email);

        if (isEmailInvalid) {
            this.setState({
                emailErrorMsg: 'Email invalide',
            });
        }

        if (!isMobileNumberValid || isEmailInvalid) return;

        try {
            //CHECK IF EMAIL DOMAIN EXISTS AND NOT A DISPOSABLE EMAIL
            const emailVerified = await verifyEmail(email);
            if (emailVerified) {
                this.props.setContactInfo({
                    firstName,
                    lastName,
                    email,
                    tel,
                });
            } else {
                this.setState({ emailErrorMsg: 'Email invalide' });
            }
        } catch (err) {
            this.setState({ emailErrorMsg: 'Email invalide' });
        }
    };

    render() {
        const {
            firstNameErrorMsg,
            lastNameErrorMsg,
            emailErrorMsg,
            telErrorMsg,
            tel,
        } = this.state;
        const isFormCompleted = this.isFormCompleted();
        return (
            <div>
                <div className="imdt-contact-form-wrapper">
                    <p className="imdt-subtitle">
                        Votre estimation est prête !
                    </p>
                    <div className="imdt-wrapper-row">
                        <div className="imdt-wrapper-column">
                            <p className="imdt-form-label">Prénom</p>
                            <input
                                className={
                                    'imdt-input imdt-input-firstname ' +
                                    (firstNameErrorMsg
                                        ? 'imdt-input-error'
                                        : '')
                                }
                                type="text"
                                onChange={this.setFirstName}
                            />
                            <p className="imdt-error-message">
                                {firstNameErrorMsg}
                            </p>
                        </div>
                        <div className="imdt-wrapper-column">
                            <p className="imdt-form-label">Nom</p>
                            <input
                                className={
                                    'imdt-input imdt-input-lastname ' +
                                    (lastNameErrorMsg ? 'imdt-input-error' : '')
                                }
                                type="text"
                                onChange={this.setLastName}
                            />
                            <p className="imdt-error-message">
                                {lastNameErrorMsg}
                            </p>
                        </div>
                    </div>
                    <div className="imdt-wrapper-row">
                        <div className="imdt-wrapper-column">
                            <p className="imdt-form-label">N° Téléphone</p>
                            {/* <PhoneInput
                                country={'fr'}
                                value={tel}
                                localization={fr}
                                placeholder={''}
                                countryCodeEditable={false}
                                containerClass={'imdt-container-input-tel ' +  (telErrorMsg ? 'imdt-container-input-tel-error' : '')}
                                inputClass={
                                    ' imdt-input-tel ' +
                                    (telErrorMsg ? 'imdt-input-error' : '')
                                }
                                onChange={(value, country, e, formattedValue) => this.setTel(value, country, e, formattedValue)}
                            /> */}
                            <PhoneInput
                                defaultCountry="FR"
                                labels={fr}
                                value={tel}
                                limitMaxLength={true}
                                addInternationalOption={false}
                                onChange={(e) => this.setTel(e)}
                                className={
                                    'imdt-container-input-tel ' +
                                    (telErrorMsg
                                        ? 'imdt-container-input-tel-error'
                                        : '')
                                }
                            />
                            <p className="imdt-error-message">{telErrorMsg}</p>
                        </div>
                        <div className="imdt-wrapper-column">
                            <p className="imdt-form-label">Email</p>
                            <input
                                className={
                                    'imdt-input imdt-input-email ' +
                                    (emailErrorMsg ? 'imdt-input-error' : '')
                                }
                                type="text"
                                onChange={this.setEmail}
                            />
                            <p className="imdt-error-message">
                                {emailErrorMsg}
                            </p>
                        </div>
                    </div>
                    <div className="imdt-optin-wrapper">
                        <p>
                            <input
                                id="imdt-optin"
                                type="checkbox"
                                onChange={this.setOptin}
                            />
                            J'accepte d'être recontacté afin d'affiner mon
                            estimation en fonction des spécificités de mon bien.
                        </p>
                    </div>
                    <p className="imdt-estimate-button">
                        <button
                            className="imdt-button"
                            disabled={!isFormCompleted}
                            onClick={() => this.submitForm()}
                        >
                            Voir l'estimation
                        </button>
                    </p>
                </div>
            </div>
        );
    }
}
