import React from 'react';
import Search from './Search/Search';
import './Address.scss';

const NOT_SUPPORTED_DEPARTMENTS = ['57', '67', '68'];
export default class Address extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: null,
        };
    }

    setAddress = (addressObj) => {
        if (addressObj && addressObj.postcode) {
            const isValidDepartment = NOT_SUPPORTED_DEPARTMENTS.reduce(
                (acc, dpt) => acc && !addressObj.postcode.startsWith(dpt),
                true
            );
            if (!isValidDepartment)
                this.setState({
                    errorMsg:
                        'Le département ' +
                        addressObj.postcode.substring(0, 2) +
                        " n'est pas couvert pas notre service pour le moment.",
                });
            else this.props.setAddress(addressObj);
        } else {
            this.setState({
                errorMsg:
                    "L'adresse renseignée est inconnue. Veuillez réessayer avec une autre adresse.",
            });
        }
    };

    render() {
        const { errorMsg } = this.state;

        return (
            <div className="imdt-address-wrapper">
                <p className="imdt-title imdt-large-padding">
                    <span className="imdt-highlight">
                        Estimez la valeur de votre bien immobilier
                    </span>{' '}
                    en quelques secondes seulement
                </p>
                <div className="imdt-address-form-wrapper">
                    <Search
                        setAddress={(addressObj) => this.setAddress(addressObj)}
                        inputPlaceholder={'Entrez une adresse'}
                    />
                    {errorMsg ? (
                        <p className="imdt-error-msg">{errorMsg}</p>
                    ) : null}
                </div>
            </div>
        );
    }
}
