import React, { Component } from 'react';
import FlipMove from 'react-flip-move';
import PropTypes from 'prop-types';
import axios from 'axios';

/**
 * Geocoder component: connects to Mapbox.com Geocoding API
 * and provides an autocompleting interface for finding locations.
 */

class Geocoder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            focus: null,
            loading: false,
            searchTime: new Date(),
            showList: false,
            inputValue: this.props.defaultInputValue
                ? this.props.defaultInputValue
                : '',
            typedInput: '',
        };
        this.input = React.createRef();
        this.handleBlur = this.handleBlur.bind(this);
        this.onInput = this.onInput.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onResult = this.onResult.bind(this);
        this.timer = null;
    }

    componentDidMount() {
        if (this.props.focusOnMount) this.input.current.focus();
    }

    search = async (
        endpoint,
        source,
        proximity,
        bbox,
        country,
        types,
        query,
        callback
    ) => {
        const searchTime = new Date();
        const uri =
            endpoint +
            '/geocoding/v5/' +
            source +
            '/' +
            encodeURIComponent(query) +
            '.json' +
            '?' +
            (proximity ? '&proximity=' + proximity : '') +
            (bbox ? '&bbox=' + bbox : '') +
            (country ? '&country=' + country : '') +
            (types ? '&types=' + encodeURIComponent(types) : '');

        try {
            const gettingGeocoding = await axios.get(uri);
            const geocodingData = gettingGeocoding
                ? gettingGeocoding.data
                : null;
            callback(false, geocodingData, searchTime);
        } catch (err) {
            callback(true, null, searchTime);
        }
    };

    onInput(e) {
        // Clear the timeout if it has already been set.
        clearTimeout(this.timer);
        const value = e.target.value;
        //We set the state variables to the current input value
        this.setState({ inputValue: value, typedInput: value });
        //We set a timer at 500ms before to trigger autocomplete
        this.timer = setTimeout(this.triggerChange.bind(this), 500);
    }

    //After the timeout, we trigger the autocomplete
    triggerChange() {
        const value = this.state.inputValue;
        this.setState({
            loading: true,
            showList: true,
        });
        this.props.onInputChange(value);
        if (value === '') {
            this.setState({
                results: [],
                focus: null,
                loading: false,
                showList: false,
            });
        } else {
            this.search(
                this.props.endpoint,
                this.props.source,
                this.props.proximity,
                this.props.bbox,
                this.props.country,
                this.props.types,
                value,
                this.onResult
            );
        }
    }

    moveFocus(dir) {
        if (this.state.loading) return;
        const focus =
            this.state.focus === null
                ? 0
                : Math.max(
                      -1,
                      Math.min(
                          this.state.results.length - 1,
                          this.state.focus + dir
                      )
                  );
        const inputValue =
            focus === -1
                ? this.state.typedInput
                : this.state.results[focus].place_name;
        this.setState({
            focus: focus,
            inputValue: inputValue,
            showList: true,
        });
        this.props.onInputChange(inputValue);
    }

    acceptFocus() {
        if (this.state.focus !== null && this.state.focus !== -1) {
            const inputValue = this.state.results[this.state.focus].place_name;
            this.setState({ showList: false, inputValue: inputValue });
            this.props.onInputChange(inputValue, true);
            this.props.onSelect(this.state.results[this.state.focus]);
        }
    }

    onKeyDown(e) {
        switch (e.which) {
            // up
            case 38:
                e.preventDefault();
                this.moveFocus(-1);
                break;
            // down
            case 40:
                e.preventDefault();
                this.moveFocus(1);
                break;
            // tab
            case 9:
                this.acceptFocus();
                break;
            // esc
            case 27:
                this.setState({ showList: false, results: [] });
                break;
            // accept
            case 13:
                if (this.state.results.length > 0 && this.state.focus == null) {
                    this.clickOption(this.state.results[0], 0);
                }
                this.acceptFocus();
                e.preventDefault();
                break;
            default:
                break;
        }
    }

    onResult(err, data, searchTime) {
        // searchTime is compared with the last search to set the state
        // to ensure that a slow response does not scramble the
        // sequence of autocomplete display.
        if (
            !err &&
            data &&
            data.features &&
            this.state.searchTime <= searchTime
        ) {
            this.setState({
                searchTime: searchTime,
                loading: false,
                results: data.features,
                focus: 0,
            });
            this.props.onSuggest(this.state.results);
        }
    }

    clickOption(place, listLocation, e) {
        // debugger
        this.props.onInputChange(place.place_name, true);
        this.props.onSelect(place);
        this.setState({
            focus: listLocation,
            showList: false,
            inputValue: place.place_name,
        });
        // Blur to blose the keyboard
        this.input.current.blur();
        if (e) {
            e.preventDefault();
        }
    }

    handleBlur(e) {
        if (
            !e ||
            !e.relatedTarget ||
            !e.relatedTarget.parentElement ||
            !e.relatedTarget.parentElement.parentElement ||
            e.relatedTarget.parentElement.parentElement.id !== 'react-geo-list'
        ) {
            this.setState({ showList: false });
        }
    }

    render() {
        const {
            inputClass,
            resultsClass,
            resultClass,
            resultFocusClass,
            inputPlaceholder,
            showLoader,
        } = this.props;
        const { inputValue, results, showList, loading, focus } = this.state;

        return (
            <div>
                <input
                    ref={this.input}
                    className={inputClass}
                    onKeyDown={this.onKeyDown}
                    placeholder={inputPlaceholder}
                    onBlur={this.handleBlur}
                    type="text"
                    value={inputValue}
                    onChange={this.onInput}
                />

                <FlipMove
                    delay={0}
                    duration={200}
                    enterAnimation="accordionVertical"
                    leaveAnimation="accordionVertical"
                    maintainContainerHeight={true}
                >
                    {results && results.length > 0 && showList && (
                        <ul
                            key="needed-for-flip-move"
                            id="react-geo-list"
                            className={
                                (showLoader && loading ? 'loading' : '') +
                                ' ' +
                                resultsClass
                            }
                        >
                            {results.map((result, i) => (
                                <li key={result.id} className="imdt-li">
                                    <a
                                        href="#"
                                        onClick={this.clickOption.bind(
                                            this,
                                            result,
                                            i
                                        )}
                                        tabIndex={'-1'}
                                        className={
                                            resultClass +
                                            ' imdt-a ' +
                                            (i === focus
                                                ? resultFocusClass
                                                : '')
                                        }
                                        key={result.id}
                                        data-transaction-search-place-type={
                                            result.properties.accuracy
                                                ? result.place_type[0] +
                                                  ' - ' +
                                                  result.properties.accuracy
                                                : result.place_type[0]
                                        }
                                        data-tracking="transaction-search-suggestion"
                                    >
                                        {result.place_name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}
                </FlipMove>
            </div>
        );
    }
}
Geocoder.defaultProps = {
    endpoint: `${process.env.API_BASE_URL}/mapbox`,
    defaultInputValue: '',
    inputClass: '',
    resultClass: '',
    resultsClass: '',
    resultFocusClass: 'strong',
    inputPlaceholder: 'Search',
    showLoader: false,
    source: 'mapbox.places',
    proximity: '',
    bbox: '',
    types: 'address',
    country: '',
    onSuggest: function onSuggest() {},
    onInputChange: function onInputChange() {},
    focusOnMount: true,
};

Geocoder.propTypes = {
    endpoint: PropTypes.string,
    defaultInputValue: PropTypes.string,
    source: PropTypes.string,
    inputClass: PropTypes.string,
    resultClass: PropTypes.string,
    resultsClass: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    resultFocusClass: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onSuggest: PropTypes.func,
    onInputChange: PropTypes.func,
    proximity: PropTypes.string,
    bbox: PropTypes.string,
    country: PropTypes.string,
    showLoader: PropTypes.bool,
    focusOnMount: PropTypes.bool,
    types: PropTypes.string,
};
export default Geocoder;
