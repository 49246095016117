import React from 'react';
import ProgressSvg from './ProgressSvg';
import './ProgressBar.scss';

export default class ProgressBar extends React.Component {
    render() {
        const { currentStep, color } = this.props;
        const steps = [
            'Adresse',
            'Caractéristiques',
            'Coordonnées',
            'Estimation',
        ];
        return (
            <div className="imdt-steps">
                {steps &&
                    steps.map((step, index) => (
                        <div
                            key={index}
                            className={
                                'imdt-step-item ' +
                                (index + 1 <= currentStep
                                    ? index + 1 === currentStep &&
                                      index + 1 !== steps.length
                                        ? 'active'
                                        : 'completed'
                                    : '')
                            }
                        >
                            <div className="imdt-step-marker">
                                {index + 1 <= currentStep ? (
                                    <span className="imdt-icon">
                                        <ProgressSvg
                                            color={color}
                                            isActive={
                                                index + 1 === currentStep &&
                                                index + 1 !== steps.length
                                            }
                                        />
                                    </span>
                                ) : null}
                            </div>
                            <div className="imdt-step-details">
                                <p className="imdt-step-title">{step}</p>
                            </div>
                        </div>
                    ))}
            </div>
        );
    }
}
