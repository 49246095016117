import React from 'react';
import './Valuation.scss';
export default class Valuation extends React.Component {
    render() {
        const { valuation } = this.props;
        return (
            <div className="imdt-valuation-wrapper">
                <p className="imdt-title">Estimation de votre bien</p>

                <div>
                    <div className="imdt-valuation-intro">
                        <p className="imdt-valuation-amount-description">Votre bien est estimé à une valeur entre </p>
                        <p className="imdt-valuation-amount">
                            <span className="imdt-highlight imdt-valuation-amount">
                                {valuation.lowerValuation === 'demo'
                                    ? 'XXX XXX'
                                    : (
                                          Math.round(
                                              valuation.lowerValuation / 1000
                                          ) * 1000
                                      )
                                          .toString()
                                          .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ' '
                                          )}
                                €
                            </span>{' '}
                            <span className='imdt-valuation-amount-text'>et</span>{' '}
                            <span className="imdt-highlight imdt-valuation-amount">
                                {valuation.upperValuation === 'demo'
                                    ? 'XXX XXX'
                                    : (
                                          Math.round(
                                              valuation.upperValuation / 1000
                                          ) * 1000
                                      )
                                          .toString()
                                          .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ' '
                                          )}
                                €
                            </span>
                        </p>
                    </div>
                    {valuation.upperValuation === 'demo' ? (
                        <p className="imdt-valuation-outro">
                            Ceci est une version de démo. L'estimation s'affiche
                            uniquement pour les utilisateurs du plan Business (
                            <a
                                className="imdt-pricing-link"
                                href={`${process.env.IMMO_DATA_URL}/tarifs`}
                            >
                                En savoir plus
                            </a>
                            )
                        </p>
                    ) : (
                        <p className="imdt-valuation-outro">
                            Vous serez bientôt recontacté afin d'affiner cette estimation en fonction des spécificités de votre bien.
                        </p>
                    )}
                </div>
            </div>
        );
    }
}
